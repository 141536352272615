import React, { useState, useContext, useRef } from 'react';
import  CgTermsContext, { useCgTermsContext }  from '../termsContext'; // Assuming you have a context defined
import hostingCfg from '../hostingcfg/hostingcfg.json'; // Assuming hosting configuration
import {
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'; // Example imports for links
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook from React Router
import DemoNavbar from '../components/Navbars/DemoNavbar'; // Example import for Navbar
import SimpleFooter from '../components/Footers/SimpleFooter'; // Example import for Footer

function FWService_html() {
    return (
        <div>
            <header>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Dendrixsemi Mircotech Embedded Firmware and Design Services</title>
            </header>
            <main>
                <h1>Dendrixsemi Mircotech: Comprehensive Embedded Firmware and Design Solutions</h1>
                <p>
                    At Dendrixsemi Mircotech, we specialize in delivering end-to-end embedded firmware and design services tailored to meet the diverse needs of our clients. Our solutions span the entire product lifecycle, from initial concept through to final manufacturing, with a strong focus on optimizing performance and ensuring reliability across various industry applications, including Avionics, Automotive, Industrial, and Medical sectors.
                </p>

                <h2>Our Embedded Services Include:</h2>
                <ul className="service-list">
                    <li><strong>Innovative System Design:</strong> We craft cutting-edge system architectures that cater to your specific requirements, ensuring optimal functionality and integration.</li>
                    <li><strong>Advanced Hardware and Mechanical Development:</strong> Our team develops robust hardware and precision-engineered mechanical components to support your embedded solutions.</li>
                    <li><strong>Custom BSP and Firmware Development:</strong> We offer tailored Board Support Packages (BSP) and firmware development, designed to meet your unique operational needs and enhance system performance.</li>
                    <li><strong>Middleware and Application Integration:</strong> Seamlessly integrate middleware solutions and third-party applications, ensuring smooth and efficient system interactions.</li>
                    <li><strong>Tailored Application Development:</strong> Create custom applications that address user needs and enhance the overall functionality of your embedded system.</li>
                    <li><strong>Rigorous Testing and Validation:</strong> Employ thorough testing and validation methods to ensure that your product meets all quality and performance standards.</li>
                    <li><strong>Streamlined Production Management:</strong> Oversee the manufacturing process to ensure high-quality production and timely delivery.</li>
                    <li><strong>Ongoing Product Lifecycle Support:</strong> Provide continuous support throughout the product lifecycle, adapting to evolving requirements and ensuring long-term success.</li>
                </ul>
                <h2>Why Choose Dendrixsemi Mircotech?</h2>
                <ul className="service-list">
                    <li><strong>Customization and Flexibility:</strong> We deliver solutions customized to fit your specific needs, including user interface design (HMI) and mechanical enclosures, all within a structured and certified process.</li>
                    <li><strong>Expertise Across Industries:</strong> With extensive experience in diverse sectors, we bring a deep understanding of industry-specific challenges and innovations.</li>
                    <li><strong>Certified Quality Assurance:</strong> Our processes adhere to rigorous standards, ensuring that every solution is built to the highest quality and reliability benchmarks.</li>
                </ul>
                <p>
                    At Dendrixsemi Mircotech, we are committed to delivering superior embedded firmware and design solutions that drive innovation and performance. Let us partner with you to bring your next project to life with precision and excellence.
                </p>
            </main>
        </div>
    );
}
const FWServices = () => {
  const [localState_GenTermsAccepted, setLocalState_GenTermsAccepted] = useState(false); // Example state
  const navigate = useNavigate(); // Hook to navigate programmatically
  const mainRef = useRef(null); // Ref for the main element

  const handleGoBack = () => {
    navigate(-1); // Go back to previous page
  };

  const company = hostingCfg.Company; // Example company variable
  return (
      <>
        <DemoNavbar />
        <main ref={mainRef}>
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-teal">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </section>
          <FWService_html />
          <section className="section section-lg pt-lg-0 section-contact-us">
                <span>                  
                  <Nav>
                    <NavItem>                    
                        <h3 style={{ textAlign: 'center' }}> Key Offerings </h3>
                        <ul>
                          <li> FW Design, Development </li>
                          <li> FW Porting across various uc Architecture</li>
                          <li> FW Footprint Optimization (RAM and FLASH) </li>
                          <li> FW Optimization for power and performance </li>                          
                          <li> OS Porting </li>
                        </ul>
                    </NavItem>
                  </Nav>
                </span>
              <p></p>
          </section>
        </main>
        <SimpleFooter />
      </>
  );
};

export default FWServices;

