/*!

=========================================================
* Argon Design System React - v1.1.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
//import { Button, Container, Row, Col } from "reactstrap";
import { Container, Row, Col } from "reactstrap";
import './logo.css'
/* Top Co Cfg */
import hostingCfg from "../../hostingcfg/hostingcfg.json"

class Hero extends React.Component {
  render() {
    const company = hostingCfg.Company;
    return (
      <>
        <div className="position-relative">
          {/* Hero for FREE version */}
          <section className="section section-hero section-shaped pb-0">
            {/* Background circles */}
         
            <div className="shape shape-style-1 shape-default" style={{ 
             background:'linear-gradient'//Gradient from purple to darker purple
                       }}>
              <span className="span-150" />
              <span className="span-50" />
              <span className="span-50" />
              <span className="span-75" />
              <span className="span-50" />              
            </div>
         
          <Container className="py-md">
            <Row className="row-grid justify-content-between align-items-center">
              <Col lg="7">
                    <h1 className="display-3 text-white" >                
                      DENDRIXSEMI MICROTECH
                    </h1> 
               {/* <img src={require("../../assets/img/brand/DendrixLogo_small_size.png")} className="App-logo" alt="logo"/> */}
                    <p className="display-5 text-white" style={{ paddingTop: '10px' }}>
                      is at the forefront of technological innovation, specializing in cutting-edge semiconductor technologies, embedded products, communication systems, and AI-driven solutions. <br/>We are dedicated to addressing our customers' needs by delivering innovative, cost-effective, and timely products and solutions that not only meet but exceed expectations.
                  {/* </p>                  
                 <p className="display-5 text-white">
Our comprehensive expertise spans the entire development process—from firmware design to pre-silicon and post-silicon stages—enabling us to tackle the most complex challenges in semiconductor and embedded product development. By integrating advanced AI and communication systems, we enhance business efficiency, drive innovation, and create solutions with a tangible, positive impact on everyday life.                
                  </p>                  
                  <p className="display-5 text-white">
Our commitment to excellence ensures that we provide exceptional value and delight for our clients, maintaining our status as a trusted partner in harnessing technology to achieve their goals.   
                */}

                    </p>
                  </Col>

                  <Col className="order-lg-2" sm="12" md="4" lg="4" xl="4">
                      <div className="card-profile-image">                      
                          <img
                            alt="..."
                            src={require("../../assets/img/theme/Designer_2.jpeg")}
                          /> 
                      </div>
                  </Col>
                                 
            </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >

              </svg>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default Hero;
