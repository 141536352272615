/*!

*/

import React , {useState} from "react";
import classnames from "classnames";
import {getAuth } from "firebase/auth"
import { httpsCallable, getFunctions } from "firebase/functions";



// reactstrap ../../components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import { Link } from "react-router-dom";

// core ../../components
import DemoNavbar from "../components/Navbars/DemoNavbar.js";
import SimpleFooter from "../components/Footers/SimpleFooter.js";
import CardsFooter from "../components/Footers/CardsFooter.js";
import axios from 'axios'; // Import Axios for making HTTP requests

import hostingCfg from "../hostingcfg/hostingcfg.json"
/*  ---- Move to Components later --------- */
const REACT_APP_PRJQUERY_BEPY_URL = process.env.REACT_APP_PRJQUERY_BEPY_URL
const functions = getFunctions();


class ProjectQuery extends React.Component {
constructor(props) {
super(props);
this.state = {
    name:'',
    email: '',
    message: '',
    isPrjClk:false
  };

  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;


  }
  
    /*  ---- Move to Components later --------- */
    handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };   
    

    handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, message, isPrjClk } = this.state;
    if(this.state.isPrjClk)
    {
      alert(' You have already submitted , Restart fresh for new requirement');
      return;
    }
    
    const auth = getAuth();
    this.setState({isPrjClk:true});
//    console.log('Auth = ', {auth})
    if(auth && auth.currentUser)
    {
        //console.log('currentUser = ', {auth.currentUser})
        try {
            console.log('Response from BE:', name, email, message);
            //console.log("project url ",REACT_APP_PRJQUERY_BEPY_URL)
    /*      const response = await axios.post('https://your-backend-api-url.com/userData', {
            name,
            email,
            message
          }); */
          //console.log('Response from BE:', response.data);
          // Optionally handle success or navigate to another page
        } catch (error) {
          console.error('Error sending data:', error);
          // Optionally handle error
        }
   /*  ---- Move to Components later --------- */
    // Call Cloud Function to process the file
    const projectQuery = httpsCallable(functions, 'projectQuery', { method: 'POST' });
    const data = { name: name, email: email, message:message };
    
    projectQuery(data)
      .then((result) => {
        console.log('Project Query result=', { result });
        if (result.data.success === true) {
          //setProcessSuccess(true);
          alert('Thank you for submitting your requirement, We will process and contact back on the email');
        } else {
          alert('Failed to process your requirement');
        }
        // setProcessedFileUrl(result.data.download_link);
      })
      .catch((error) => {
        console.error('Error processing message:', error);
        if (error.error === 'Failed to process Message') {
          alert('Failed to process Message');
        } else {
          alert('An unexpected error occurred.Ensure you are logged in. Try logging in with google else try later');
        }
      })
      .finally(() => {
        
      });
    /*  ---- Move to Components later --------- */    
    }
    else
        alert('You are not signed in to send Information, Register/Login first');
  };


  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-teal">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center ">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Looking reliable partner for your project, Look no further </h4>
                      <p className="mt-0">Your project is very important to us. Share your requirement/contact (Accepted from Signed-In users only) </p>
                      <form onSubmit={this.handleSubmit}>
                        <FormGroup className={classnames("mt-5", { focused: this.state.nameFocused })}>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-user-run" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Your name"
                              type="text"
                              name="name"
                              value={this.state.name}
                              onFocus={() => this.setState({ nameFocused: true })}
                              onBlur={() => this.setState({ nameFocused: false })}
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className={classnames({ focused: this.state.emailFocused })}>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email address"
                              type="email"
                              name="email"
                              value={this.state.email}
                              onFocus={() => this.setState({ emailFocused: true })}
                              onBlur={() => this.setState({ emailFocused: false })}
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-4">
                          <Input
                            className="form-control-alternative"
                            cols="180"			    
                            name="message"
                            placeholder="Type a message..."
                            type="textarea"
                            value={this.state.message}
                            onChange={this.handleChange}
                            rows="4"
                          />
                        </FormGroup>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="submit"
                          disabled={this.state.isPrjClk}  // Disable button already submitted
                        >
                          Send Message
                        </Button>
                      </form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>           
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default ProjectQuery
