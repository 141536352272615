/*!

=========================================================
* Argon Design System React - v1.1.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)
*
* Coded by Creative Tim
* Modified by Mukesh
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, {useState} from "react";

/* Top Co Cfg */
import hostingCfg from "../../hostingcfg/hostingcfg.json"
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

const ContactLink = ({ company }) => {

  return (
      <NavLink
        href={`mailto:${company.contactEmail}?Subject Contact Support`}
        title={`Phone:- ${company.phoneNumber} \n${company.contactEmail} `}
        target="_blank"
      >
        Contact Us
      </NavLink>
  );
};


class SimpleFooter extends React.Component {

  render() {
    const company = hostingCfg.Company;
    /*const privacy_page = {company.website}"/Privacy_policy.htm"
    console.log("privacy_page {privacy_page}") */
    return (
      <>
        <footer className=" footer">
          <Container>
            <Row className=" row-grid align-items-center mb-5">
              <Col lg="7">
                <h3 className=" text-primary font-weight-light mb-2">
                  Your support fuels our passion!
                </h3>
                <h4 className=" mb-0 font-weight-light">
                  Let's get in touch on any of these platforms.
                </h4>
              </Col>
              <Col className="text-lg-center btn-wrapper" lg="5">
                <Button
                  className="btn-icon-only rounded-circle"
                  color="twitter"
                  href={company.linkedinHdl}
                  id="tooltip475038074"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-linkedin" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip475038074">
                  Follow us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="facebook"
                  href={company.fbHdl}
                  id="tooltip837440414"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-facebook-square" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip837440414">
                  Like us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="dribbble"
                  href={company.contactUsPage}
                  id="tooltip829810202"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-dribbble" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip829810202">
                  Contact us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="github"
                  href={company.gitHdl}
                  id="tooltip495507257"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-github" />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip495507257">
                  Star on Github
                </UncontrolledTooltip>
              </Col>
            </Row>
            <hr />
            <Row className=" align-items-center justify-content-md-between">
              <Col md="4">
                <div className=" copyright">
                  © {new Date().getFullYear()} {" "}
                  <a
                    href={company.website}
                    target="_blank"
                  >
                    {company.Shortname}
                  </a>
                </div>
              </Col>
              <Col md="7">
                <Nav className=" nav-footer justify-content-end">
                  <NavItem>
                    <NavLink
                      href={company.website}
                      target="_blank"
                    >
                      {company.Shortname}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <ContactLink company={company} />
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href={`${company.website}/Privacy_policy.htm`} rel="noopener noreferrer"
                      target="_blank"
                    >
                      Privacy Policy
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://github.com/Mk-dsm/DendrixFE_License/blob/main/LICENSE"
                      target="_blank"
                    >
                      License
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <Row className=" align-items-center justify-content-md-between">
              <Col md="6">
                <div className=" copyright">
                  <a
                    href={company.website}
                    target="_blank"
                  >
                    {company.CoCin}
                  </a>
                </div>
              </Col>
            </Row>                          
          </Container>
        </footer>
      </>
    );
  }
}

export default SimpleFooter;
